.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sc-hKMtZM.sc-ftvSup.imjOZz.ejwJAK button.sc-iBkjds.eKwPae svg {
  color: white;
}
.back-text:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1356px) and (min-width: 768px) {
  .monthly-visits {
    font-size: 13px;
  }
}
@media only screen and (max-width: 954px) and (min-width: 768px) {
  .monthly-visits {
    font-size: 10px;
  }
}
@media only screen and (max-width: 811px) and (min-width: 768px) {
  .monthly-visits {
    font-size: 8px;
  }
}

@media screen and (min-width: 768px) {
  .main-panel .panel-body .panel-right {
    width: 80% !important;
  }
  .messages {
    width: 50% !important;
    margin-left: 50px !important;
  }
}

.main-panel .panel-body {
  background: white;
  display: flex;
}
.main-panel .panel-body .panel-left {
  width: 5%;
}
.main-panel .panel-body .panel-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 25px 15px;
  border-left: 3px solid #5addfc;
}
.main-panel .panel-body .panel-right .card {
  width: 100%;
  cursor: pointer;
  position: relative;
  background: linear-gradient(359deg, #d509091c, transparent);
}
.main-panel .panel-body .panel-right .card .card-log {
  font-size: 12px;
  color: #7b7b7b;
  font-weight: 600;
}
.main-panel .panel-body .panel-right .card p {
  font-size: 16px;
  color: #7b7b7b;
  margin-top: 5px;
}
.main-panel .panel-body .panel-right .card::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  transform: translateX(-17px);
  z-index: 20;
  width: 10px;
  height: 10px;
  background: #5addfc;
}

/* --------------------------- */

.messages {
  width: 85%;
  height: auto;
  float: left;
  margin-left: 20px;
}

.messages .messages__item {
  border-left: 8px solid #5addfc;
  background: #fff;
  box-shadow: 9px 9px 30px 0px rgba(107, 57, 57, 0.21);
  border-radius: 2px 7px 7px 2px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 15px;
  overflow: hidden;
}
.messages .messages__item .name {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
}
.messages .messages__item .date {
  float: right;
  color: #c0c0c0;
}
.messages .messages__item .content {
  float: left;
  /* padding: 10px 0; */
  color: #565656;
  font-size: 15px;
}
